.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode {
  height: 60px;
  padding: 4px;

  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .dndnode.httpActionNode {
  border-color: #00ff59;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

.input-container {
  position: relative;
}

.input-container input {
  width: 100%;
  border: none;
  position: absolute;
  inset: 0;
  z-index: 1;
  outline: none;
  /* padding: 0; */
  font-size: 14px;
  background: transparent;
}
/* For showing through placeholder */
.input-container input:not([value=""]) {
  -webkit-text-fill-color: transparent;
}

.input-container .input-renderer {
  font-size: 14px;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  margin: 0;
  /* This line preserves trailing
      whitespaces */
  white-space: pre;

  overflow-x: auto;
  user-select: none;
  scrollbar-width: none;
}

.input-container .input-renderer::-webkit-scrollbar {
  display: none;
}
