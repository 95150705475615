.generic-table tr:first-child td:first-child {
  border-top-left-radius: 6px;
}
.generic-table tr:first-child td:last-child {
  border-top-right-radius: 6px;
}

.generic-table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}
.generic-table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.generic-table tr:first-child td {
  border-top-style: solid;
}
.generic-table td:first-child {
  border-left-style: solid;
}
