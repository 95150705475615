/* Y-Labels */
.heatmap-container > div :nth-child(1) > div > div {
  font-size: 8px !important;
  white-space: nowrap;
  /* margin-top: -2px; */
}

/* all X-Labels */
.heatmap-container > div > div:nth-child(2) > div:nth-child(1) > div > div {
  font-size: 8px !important;
  /* white-space: nowrap !important; */
  margin-top: 24px !important;
  line-height: 12px;
  overflow: visible !important;
  transform: translateX(-5px) rotateZ(-45deg) !important;
}

/* even X-Labels */
.heatmap-container
  > div
  > div:nth-child(2)
  > div:nth-child(1)
  > div
  :nth-child(even) {
  color: transparent;
}

/* cells */
.heatmap-container > div > div:nth-child(2) > div:nth-child(2) > div > div {
  border-color: rgb(var(--color-background)) !important;
}
