@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* iframe#webpack-dev-server-client-overlay {
  display: none !important;
} */
body #root {
  min-height: 100vh;
}

/* react-leaflet-draw */
.sr-only {
  display: none;
}

@layer components {
  .golain {
    --color-primary: 84 108 204;
    --color-primary-light: 101 131 249;
    --color-content: 31 41 55;
    --color-content-light: 133 135 143;
    --color-content-lighter: 193 195 213;
    --color-background: 255 255 255;
    --color-background-layer05: 250 250 250;
    --color-background-layer1: 245 245 245;
    --color-background-layer2: 233 234 236;
    --color-background-layer3: 221 222 224;
  }

  .dark-default {
    --color-primary: 84 108 204;
    --color-primary-light: 101 131 249;
    --color-content: 215 215 215;
    --color-content-light: 183 185 193;
    --color-content-lighter: 133 135 143;
    --color-background: 31 41 55;
    --color-background-layer05: 36 46 60;
    --color-background-layer1: 41 55 71;
    --color-background-layer2: 51 65 81;
    --color-background-layer3: 61 75 91;
  }
}

.debounced-input input {
  color: theme("colors.contentColor");
}

.debounced-input input::placeholder {
  color: theme("colors.contentColorLight");
}

.ag-theme-alpine.ag-theme-custom {
  --ag-foreground-color: rgb(var(--color-content));
  --ag-background-color: rgba(var(--color-background-layer1));
  --ag-odd-row-background-color: rgb(var(--color-background-layer2));
  --ag-data-color: rgb(var(--color-content));
  --ag-header-background-color: rgb(var(--color-background-layer2));
  --ag-secondary-foreground-color: rgb(var(--color-content));
  --ag-modal-overlay-background-color: rgba(var(--color-background-layer3));
  --ag-tooltip-background-color: rgb(var(--color-background-layer2));
  --ag-border-color: rgb(var(--color-background-layer2));
  --ag-advanced-filter-join-pill-color: rgb(var(--color-background-layer2));
  --ag-advanced-filter-column-pill-color: rgb(var(--color-background-layer2));
  --ag-control-panel-background-color: rgb(var(--color-background-layer2));
  --ag-alpine-active-color: rgb(var(--color-primary));
}

.ag-theme-alpine.ag-theme-custom .ag-body-horizontal-scroll-viewport {
  border-color: rgb(var(--color-background-layer3));
  background-color: rgb(var(--color-background-layer3));
}

.ag-theme-alpine.ag-theme-custom .ag-body-vertical-scroll-viewport {
  border-color: rgb(var(--color-background-layer2));
  background-color: rgb(var(--color-background-layer2));
}

.ag-theme-alpine.ag-theme-custom .ag-body-vertical-scroll-viewport:hover {
  border-color: rgb(var(--color-background-layer2));
  background-color: rgb(var(--color-background-layer2));
}

::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgb(var(--color-background-layer1));
}

::-webkit-scrollbar-thumb {
  background: rgb(var(--color-background-layer2));
  border-radius: 50px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(var(--color-background-layer3));
}

[type="checkbox"]:checked {
  background-image: unset;
}

.react-grid-item.hide-resize .react-resizable-handle {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(var(--color-background-layer2)) inset !important;
  -webkit-text-fill-color: rgb(var(--color-content)) !important;
}

.py-error {
  display: none;
}
